import React from 'react';

export default function List( props ) {

	return (
		<>
			{ props.list  && (
				<ul className={ props.counter === 0 ? 'panel revealed' : 'panel' } id={ props.title }>

					{props.list.map((listItem, i) => {
						if( listItem.url ) {

							return (
								<li key={ i }>
									<a target="_blank" rel="noreferrer" href={listItem.url}>
										{ listItem.listItem }
									</a>
								</li>
							);

						} else {
							return (
								<li key={ i }>
									{ listItem.listItem }
								</li>
							);
						}
					})}

				</ul>
			)}
		</>
	);

}