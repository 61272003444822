import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import StudioGallery from '../components/studio-profile/StudioGallery';
import ContactDetails from '../components/studio-profile/ContactDetails';
import PageNav from '../components/studio-profile/PageNav';
import Text from '../components/studio-profile/Text';
import List from '../components/studio-profile/List';
import Testimonial from '../components/studio-profile/Testimonial';
import FooterImages from '../components/studio-profile/FooterImages';
import '../styles/components/studio-profile.scss';
import '../styles/components/panel.scss';


let container, gallery, finalSection, topValue, topValueDigit, finalSectionPosition, newFixedPosition, windowWidth;
let theWindow = typeof window !== 'undefined' ? window : '';

const kebabCase = (str) => {
	const kebab = str.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    	.join('-')
    	.toLowerCase();

    return kebab;
}

class StudioProfile extends React.Component {

	constructor( props ) {
		super( props );

		this.state = {
			width: theWindow.innerWidth
		}
		
		this.updateWindowDimensions = this.updateWindowDimensions.bind( this )

	}

	componentDidMount() {
    	window.addEventListener( 'resize', () => {
			this.updateWindowDimensions();
    	});
	}

	updateWindowDimensions() {

		this.setState({
			width: theWindow.innerWidth
		});

	}	

	render() {

		return(
			<>
			<StaticQuery 
				query={	graphql`
					query StudioProfile {
					wpPage(id: {eq: "cG9zdDoxNA=="}) {
						id
					    title
					    content
					    uri
					    studioProfile {
					    sections {
					        sectionTitle
					        text
					        type
					        list {
					          listItem
					          url
					        }
					        testimonials {
					          quote
					          cite
					        }
					      }
					    }
					    studioGallery {
					      studioGallery {
					      	caption
					        localFile {
					          url
					          childImageSharp {
					           fluid(maxWidth: 800) {
				            		...GatsbyImageSharpFluid_noBase64
			     	 				...GatsbyImageSharpFluidLimitPresentationSize
						        }
					          }
					        }
					      }
					    }
					    footerImages {
					      footerImages {
					        left {
					          localFile {
					            childImageSharp {
					              fluid(maxWidth: 800) {
									...GatsbyImageSharpFluid_noBase64
					              	...GatsbyImageSharpFluidLimitPresentationSize
					              }
					            }
					          }
					        }
					        right {
					          localFile {
					            childImageSharp {
					              fluid(maxWidth: 1200) {
									...GatsbyImageSharpFluid_noBase64
					              	...GatsbyImageSharpFluidLimitPresentationSize
					              }
					            }
					          }
					        }
					      }
					    }
					    seo {
					      metaDesc
					      metaKeywords
					      metaRobotsNoindex
					      focuskw
					      opengraphAuthor
					      opengraphDescription
					      opengraphImage {
					        localFile {
					          childImageSharp {
					            fixed {
					              src
					            }
					          }
					        }
					      }
					      opengraphModifiedTime
					      opengraphPublishedTime
					      opengraphPublisher
					      opengraphSiteName
					      opengraphTitle
					      opengraphType
					      opengraphUrl
					      title
					      twitterDescription
					      twitterImage {
					        localFile {
					          childImageSharp {
					            fixed {
					              src
					            }
					          }
					        }
					      }
					      twitterTitle
					    }
					}
					wp {
					    generalSettings {
					      title
					      description
					    }
						siteSettings {
						  settings {
						    address
						    email
						    telephone
						    instagramHandle
						  }
						}
					}
				}
			`}
			render={ (studioProfile)  => (

				<>
					<Seo 
						bodyClasses="bg:putty"
				    	siteTitle={studioProfile.wp.generalSettings.title}
						siteDescription={studioProfile.wp.generalSettings.description}
						title={studioProfile.wpPage.seo.title}
						metaDesc={studioProfile.wpPage.seo.metaDesc}
						focusKeywords={studioProfile.wpPage.seo.focuskw}
						opengraphTitle={studioProfile.wpPage.seo.opengraphTitle}
						opengraphDescription={studioProfile.wpPage.seo.opengraphDescription}
						opengraphImage={studioProfile.wpPage.seo.opengraphImage}
						opengraphImage={studioProfile.wpPage.seo.opengraphImage}
						twitterDescription={studioProfile.wpPage.seo.twitterDescription}
						twitterImage={studioProfile.wpPage.seo.twitterImage}
						twitterTitle={studioProfile.wpPage.seo.twitterTitle}
				    />

					<Layout>
						<Header 
							title={ studioProfile.wpPage.title } 
							url={ studioProfile.wpPage.uri } 
							id={ studioProfile.wpPage.id }
						/>
							<div className="studio-profile flex">
								<aside className="flex">
									<StudioGallery 
										gallery={ studioProfile.wpPage.studioGallery.studioGallery }
									/>

									<div className="flex" id="contact">
										<ContactDetails 
											address={ studioProfile.wp.siteSettings.settings.address }
							        		email={ studioProfile.wp.siteSettings.settings.email }
							        		telephone={ studioProfile.wp.siteSettings.settings.telephone }
							        		instagramHandle={ studioProfile.wp.siteSettings.settings.instagramHandle }
										/>
										<PageNav 
											pageWidth={ this.state.width }
					        				sections={ studioProfile.wpPage.studioProfile.sections }
					        			/>
									</div>
								</aside>

								<article>
				 		        	{ studioProfile.wpPage.studioProfile.sections.map((section, i) => {
						        		if( section.type === 'text' ) {
						        			return (
						        				<Text 
						        					counter={i}
						        					title={ kebabCase( section.sectionTitle ) }
						        					key={i}
						        					text={ section.text } 
						        				/>
						        			)
						        		} else if( section.type === 'list' ) {
						        			return (
						        				<List 
						        					counter={i}
						        					title={ kebabCase( section.sectionTitle ) }
						        					key={i} 
						        					list={ section.list } 
						        				/>
						        			)
						        		} else if( section.type === 'testimonials' ) {
						        			return (
						        				<Testimonial 
						        					counter={i}
						        					title={ kebabCase( section.sectionTitle ) }
						        					key={i} 
						        					testimonials={ section.testimonials } 
						        				/>
						        			)
						        		}
						        	}) }	
								</article>
							</div>
							
							<FooterImages 
		        				leftImage={ studioProfile.wpPage.footerImages.footerImages.left }
		        				rightImage={ studioProfile.wpPage.footerImages.footerImages.right }
		        			/>
							
	        			<Footer />
					</Layout>
				</>

			)}
			/>
			</>

		)

	}

}

export default StudioProfile;