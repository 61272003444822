import React from 'react';

export default function Text( props ) {

	return (
		<>
			{ props.text && (
				<section id={ props.title } className={ props.counter === 0 ? 'panel content revealed' : 'panel content' } 
					dangerouslySetInnerHTML={{ __html: props.text }}
				/>
			)}
		</>
	);

}