import React from 'react';
import Swiper from '../utils/Swiper';

export default function StudioGallery( props ) {
	const gallery = props.gallery;

	return (
		<>
		{ gallery && (
			<div>
				<Swiper galleryImages={ gallery } />
			</div>
		)}
		</>
	);

}