import React from 'react';
import '../../styles/components/blockquote.scss';

export default function Testimonial( props ) {

	return (
		<>
		{ props.testimonials  && (
			
			<div className={ props.counter === 0 ? 'panel revealed' : 'panel' } id={ props.title }>
			{props.testimonials.map((testimonial, i) => (
				<blockquote key={i}>
					<p>{testimonial.quote}</p>
					<cite className="text:small">{testimonial.cite}</cite>
				</blockquote>
			))}
			</div>
			
		)}
		</>
	);
}