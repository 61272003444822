import React from 'react';
import Obfuscate from 'react-obfuscate';

export default function ContactDetails( props ) {

	const emailLink = props.email;
	const phoneLink = props.telephone.replace(/[- )(]/g,'');
	const instagramPath = props.instagramHandle.replace( '@', '' );

	return (
		<div>

			{ props.address && (
				<address 
					dangerouslySetInnerHTML={{ __html: props.address }}
				/>
			)}
			
			{ props.email && (
				<Obfuscate 
					email={ props.email }
				/>
			)}
			
			{ props.telephone && (
				<p>
					<a href={ "tel:" + phoneLink }>
						{ props.telephone }
					</a>
				</p>
			)}

			{ props.instagramHandle && (
				<p>
					Follow: <a target="_blank" rel="noreferrer" href={ "https://instagram.com/" + instagramPath }>{props.instagramHandle}</a>
				</p>
			)}
		
		</div>
	)
}