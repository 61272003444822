import React from 'react';

export default function PageNav( props ) {

	let list, listItems, panelRef, panelTarget, styles, container, margin, scrollValue, panels;

	const setActiveAndReveal = ( event, index ) => {
		event.preventDefault();

		list 			= event.target.parentNode.parentNode;
		listItems 		= list.querySelectorAll( 'li' );
		panelRef 		= event.target.getAttribute("href");
		panelTarget 	= document.querySelector( panelRef );
		container 		= document.querySelector( 'article' );
		panels 			= document.querySelectorAll( '.panel' );

		listItems.forEach(listItem => {
			listItem.classList.remove( 'active' );
		});

		event.target.parentNode.classList.add( 'active' );
		styles 			= window.getComputedStyle( panelTarget );
		margin 			= parseInt(styles.marginTop);

		if( props.pageWidth < 811 ) {

			if( index === 0 ) {
				scrollValue = 0;
			} else {
				scrollValue = panelTarget.offsetTop;
			}

			panelTarget.scrollIntoView({
				behavior: 'smooth'
			});

		} else {

			panels.forEach(panel => {
				panel.classList.remove( 'revealed' );
			});

			setTimeout(() => {
				panelTarget.classList.add( 'revealed' );
			}, 500);

		}

	}

	const kebabCase = str => str
        .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
        .join('-')
        .toLowerCase();

	return (
		<>
		{ props.sections && (
			<nav>
				<ul>
					{props.sections.map((section, i) => {
						if( i === 0 ) {
							return (
								<li onClick={(e) => {
									setActiveAndReveal(e, i);
								}} className={ 'active' }>
									<a href={ "#" + kebabCase( section.sectionTitle ) }>{ section.sectionTitle }</a>
								</li>
							);
						} else {
							return (
								<li onClick={(e) => {
									setActiveAndReveal(e, i);
								}}>
									<a href={ "#" + kebabCase( section.sectionTitle ) }>{ section.sectionTitle }</a>
								</li>
							);
						}
					})}
				</ul>
			</nav>
		)}
		</>
	);

}