import React from 'react';
import Img from 'gatsby-image';
import '../../styles/components/footer-images.scss';

export default function FooterImages( props ) {

	return (
		<div id="trigger" className="footer-images flex">
			
			{ props.leftImage.localFile.childImageSharp.fluid && (
				<figure>
					<Img fluid={ props.leftImage.localFile.childImageSharp.fluid } />
				</figure>
			)}

			{ props.leftImage.localFile.childImageSharp.fluid && (
				<figure>
					<Img fluid={ props.rightImage.localFile.childImageSharp.fluid } />
				</figure>
			)}

		</div>
	);

}